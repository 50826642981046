import styled from "styled-components";

export const ImgBoxMange = styled.div`
  display: flex;
  .ant-image{
    margin-left: 10px;
    overflow: hidden;
    &:nth-of-type(1){
      margin-left: 0px;
    }
  }
`