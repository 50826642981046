import bg from '../../images/login_bg.svg'
import styled from "styled-components";

export const Main = styled.div`
  width: 100vw;
  height: 100vh;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  background-image: url(${bg});
  background-position: center center;
  background-size: cover;
  padding: 5vw;
  box-sizing: border-box;
  
  .searchBox{
    //width: 20vw;
    padding-bottom: 20px;
  }
`

export const ImgBox = styled.div`
  //width: 60vw;
  //height: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  //padding: 0 15px 15px;
  box-sizing: border-box;
  position: relative;

  .example {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 30px 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
  
  .img_box{
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .ant-image {
    margin-left: 5px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }

  .not_found {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .show_context {
    .title {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ccc;
      font-size: 24px;
      font-weight: bold;
      text-indent: 10px;
    }

    .row_box {
      width: 100%;

      .row {
        padding: 5px;
        font-size: 16px;
        display: flex;
        align-items: top;
        .label{
          width: 100px;
        }
        .row_content{
          width: calc(100% - 100px);
          word-wrap: break-word;
          font-weight: bold;
        }
      }
    }
  }
`