import {withRouter} from "react-router-dom";
import axios from "axios";

/**
 * 处理用户登录组件
 */

const Is_login = props => {
  const {pathname} = props.location;
  if (pathname.indexOf("/detail/") === -1 && pathname.indexOf("/hashSearch") === -1) {
    axios
      .post("/jie/api/new/is_login")
      .then(res => {
        const {data} = res;
        if (data.ecode === 0 && pathname === "/login") {
          props.history.replace("/");
        } else if (data.ecode !== 0 && pathname !== "/login") {
          props.history.replace("/login");
        }
      })
      .catch(err => {
        const {pathname} = props.location;
        if (pathname !== "/login") {
          props.history.replace("/login");
        }
      });
  }
  return "";
};

export default withRouter(Is_login);
