import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  height: 60px;
  //border-bottom:1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  .form_box {
    display: flex;

    .search {
      width: 300px;
      margin-right: 15px;
    }
  }



`

export const AList = styled.div`
    margin-top:20px;
    position: relative;
    min-height:681px;

    .ant-list-item-meta-description{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        width:80%;
    }

    .loading{
        text-align:center;
        position:absolute;
        left:0;
        top:0;
        height:100%;
        width:100%;
        z-index:100;
        background-color:rgba(255,255,255,.8);
        display:flex;
        align-items:center;
        justify-content:center;
    }

    

`

export const PageBox = styled.div`
    width:100%;
    text-align:center;
    padding:20px 0;
`

export const EditorItem = styled.div`
    display:flex;
    justify-content:space-between;
    padding:0 30px;
    box-sizing:border-box;
    .edito-item{
        width:150px;
        height:180px;
        flex-shrink:0;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        cursor: pointer;
        border-radius:4px;
        overflow:hidden;
        :hover{
            border:1px dashed #1890ff;
        }
        :hover .edito-name{
            color:#1890ff;
        }
        .edito-icon{
            font-size:80px;
        }
        .edito-name{
            font-size:18px;
            color:rgba(0, 0, 0, 0.85);
        }
        .edito-context{
            font-size:14px;
            color:#999;
        }
    }
`

export const CodeBox = styled.div`
  display: flex;
  justify-content: center;
  .ant-qrcode{
    padding: 0 !important;
  }
`

export const ShowCode = styled.div`
  text-align: center;
  padding: 10px;
`
