import React from "react";

import { LoginMain, FormmRow } from "./style";
import { Input, Button, message } from "antd";
import logo from "../../images/logo.png";
import axios from "axios";
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone, LockOutlined } from "@ant-design/icons";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			send: false,
		};
	}

	render() {
		return (
			<LoginMain>
				<div className="login_header">
					{/*<img src={logo} alt="成长笔记本" />*/}
					<div className="logo">
						<span>鉴证溯源后台</span>
					</div>
				</div>
				<FormmRow>
					<Input
						value={this.state.username}
						onChange={value => this.setState({ username: value.target.value })}
						placeholder="请输入账号"
						prefix={<UserOutlined />}
						onKeyDown={this.handlePasswordKeyDown}
					/>
				</FormmRow>
				<FormmRow>
					<Input.Password
						placeholder="请输入密码"
						prefix={<LockOutlined />}
						value={this.state.password}
						onChange={value => this.setState({ password: value.target.value })}
						onKeyDown={this.handlePasswordKeyDown}
						iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
					/>
				</FormmRow>
				<FormmRow style={{ marginTop: "50px" }}>
					<Button loading={this.state.send} type="primary" onClick={this.handleUserLogin}>
						登录
					</Button>
				</FormmRow>
			</LoginMain>
		);
	}

	// 回车登录
	handlePasswordKeyDown = e => {
		const { keyCode } = e;
		if (keyCode === 13) {
			this.handleUserLogin();
		}
	};

	// 用户登录
	handleUserLogin = () => {
		const { username, password } = this.state;
		if (username === "") {
			message.warning("请输入账号！！");
			return;
		}
		if (password === "") {
			message.warning("请输入密码！！");
			return;
		}
		this.setState({
			send: true,
		});
		axios
			.post("/jie/api/new/login", {
				username,
				password,
			})
			.then(res => {
				const { data } = res;
				if (data.ecode === 0) {
					message.success("登录成功");
					this.props.history.push("/");
				} else {
					message.error(data.msg);
					this.setState({
						send: false,
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	};
}

export default Login;
