import React, {Component} from "react";
import {CodeBox, Header, ShowCode} from "./style";
import {Input, Button, message, Popconfirm, Pagination, Image, QRCode, Popover, Table, Switch, Select} from "antd";
import {createFromIconfontCN} from "@ant-design/icons";
import Axios from "axios";
import axios from "axios";

const {Search} = Input;

// const HOST = "http://10.13.31.140:3000/detail/";

const HOST = `${window.location.protocol}//${window.location.host}/detail/`;

class ArticleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
      formData: {length: 10, page: 1, key: "", column: ''},
      loading: true,
      delState: {confirmLoading: false, id: ""},
      pathname: props.location.pathname,
      showAddType: false,
      pagination: {current: 1, pageSize: 10, total: 0},
      columnList: []
    };
    this.columns = [
      {
        title: "标题", align: 'center', dataIndex: 'title', render: (title, item) => {
          return item.is_show === 1 ? (
            <a href={`${HOST}${item.id}`} target="_blank">
              {item.title}
            </a>
          ) : (
            <a>{item.title}</a>
          )
        }
      },
      {
        title: '封面', align: 'center', dataIndex: 'logo', render: (logo) => <Image width={150} src={logo}/>
      },
      {title: '副标题', dataIndex: 'title_two', key: 'title_two', align: 'center'},
      {
        title: "状态",
        dataIndex: 'is_show',
        align: 'center',
        render: (is_show, item) => <Switch onChange={e => this.handleShowArticle(e, item.id)} checkedChildren="显示" unCheckedChildren="隐藏" defaultChecked={item.is_show === 1}
                                           checked={item.is_show === 1}/>
      },
      {title: '栏目', dataIndex: 'column', key: 'column', align: 'center'},
      {
        title: "二维码", align: 'center', dataIndex: 'id', render: (id) => <Popover
          overlayInnerStyle={{padding: 0}}
          content={
            <ShowCode id="myqrcode">
              <QRCode value={`${HOST}${id}`} bordered={false} color="#FF8C00" size={200}/>
              <Button type="primary" onClick={this.downloadQRCode}>
                下载二维码
              </Button>
            </ShowCode>
          }
        >
          <CodeBox>
            <QRCode value={`${HOST}${id}`} bordered={false} size={70} color="#FF8C00"/>
          </CodeBox>
        </Popover>
      },
      {
        title: "操作", align: 'center', dataIndex: '', render: (on, item) => {
          return (
            <>
              <Button style={{marginRight: "10px", marginLeft: "10px"}} type="primary" onClick={() => this.handleGoToArticleSet(item)}>
                编辑
              </Button>
              <Popconfirm
                title="确定要删除吗？"
                okText="确定"
                placement="topRight"
                okButtonProps={{loading: this.state.delState.confirmLoading}}
                visible={item.show}
                cancelText="我再想想"
                onConfirm={this.handleConfirmDel}
                onCancel={() => this.handleHideConfirm(item.id)}
              >
                <Button type="primary" danger onClick={() => this.handleDelArticleItem(item.id)}>
                  删除
                </Button>
              </Popconfirm>
            </>
          )
        }
      }
    ]
    this.changeSelectColumn = this.changeSelectColumn.bind(this)
  }

  render() {
    return (
      <div>
        <div>
          <Header>
            <div className="form_box">
              <div className="search">
                <Search placeholder="输入文章标题" value={this.state.formData.key} onSearch={this.handleSearchArticle} onChange={this.handleFormKeyChange} enterButton/>
              </div>
              <Select style={{width: 200}} allowClear={true} placeholder="栏目选择" onChange={this.changeSelectColumn} options={this.state.columnList}></Select>
            </div>
            <div className="add">
              <Button type="primary" onClick={this.addArticleSelect}>
                添加
              </Button>
            </div>
          </Header>
        </div>
        <Table dataSource={this.state.list} columns={this.columns} loading={this.state.loading} pagination={this.state.pagination} onChange={this.handleChangeTable}/>
      </div>
    );
  }

  componentDidMount = () => {
    this.handleGetArticleList();
    this.handleGetColumnList()
  };

  changeSelectColumn(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        column: value
      },
      pagination: {
        ...this.state.pagination
      },
      loading: true
    }, () => {
      this.handleGetArticleList()
    })
  }

  handleChangeTable = (page) => {
    this.handleChangePages(page.current)
  }

  // 获取栏目信息
  handleGetColumnList() {
    const params = {page: 1, length: 99999}

    axios.get('/jie/api/new/getColumn', {
      params
    }).then(res => {
      const {data} = res.data.data
      this.setState({
        columnList: data.map(item => {
          item.key = item.id
          item.value = item.id
          item.label = item.title
          return item
        }),
      })
    }).catch(err => {
      message.error("系统错误，无法获取栏目列表，请稍后再试")
    })
  }

  // 下载二维码
  downloadQRCode = () => {
    const canvas = document.getElementById("myqrcode")?.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  // 选择新建文章类型
  addArticleSelect = () => {
    this.props.history.push("/article/add_default/0")
  };

  // 前往文章编辑
  handleGoToArticleSet = obj => {
    if (obj.ctype === 0) {
      this.props.history.push("/article/add_default/" + obj.id);
    } else {
      this.props.history.push("/article/add/" + obj.id);
    }
  };

  // 分页功能
  handleChangePages = e => {
    const {formData} = this.state;
    formData.page = e;
    this.setState(
      {
        formData,
        loading: true,
      },
      () => {
        this.handleGetArticleList();
      }
    );
  };

  // 取消删除
  handleHideConfirm = id => {
    let {list} = this.state;
    list = list.map(item => {
      if (item.id === id) item.show = false;
      return item;
    });
    this.setState({
      list,
    });
  };

  // 显示||隐藏文章
  handleShowArticle = (e, id) => {
    let {list} = this.state;
    list = list.map(item => {
      if (id === item.id) {
        item.is_show = e ? 1 : 0;
      }
      return item;
    });
    this.setState({
      list,
    });

    Axios.post("/jie/api/new/updateArticleState", {id, act: 1, show: e ? 1 : 0})
      .then(res => {
        if (res.data.ecode === 0) {
          message.success("操作成功");
        } else {
          list = list.map(item => {
            if (id === item.id) item.is_show = !e ? 1 : 0;
            return item;
          });
          this.setState({list});
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  // 删除文章
  handleDelArticleItem = id => {
    let {list, delState} = this.state;
    list = list.map(item => {
      if (item.id === id) {
        item.show = !item.show;
      } else {
        item.show = false;
      }
      return item;
    });
    delState.id = id;
    this.setState({list, delState});
  };

  // 确认删除文章
  handleConfirmDel = () => {
    let {delState, list} = this.state;
    delState.confirmLoading = true;
    this.setState(
      {delState, loading: true},
      () => {
        Axios.post("/jie/api/new/updateArticleState", {
          id: delState.id,
          act: 2,
        })
          .then(res => {
            if (res.data.ecode === 0) {
              list = list.filter(item => item.id !== delState.id);
              delState.confirmLoading = false;
              this.setState(
                {
                  list,
                  delState,
                },
                () => {
                  message.success("删除成功");
                  this.handleGetArticleList();
                }
              );
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  // 搜索关键字修改
  handleFormKeyChange = e => {
    const {value} = e.target;
    const {formData} = this.state;
    formData.key = value;
    this.setState({
      formData,
    });
  };

  // 搜索文章
  handleSearchArticle = () => {
    const {formData} = this.state;
    formData.page = 1;
    this.setState(
      {
        loading: true,
        formData,
      },
      () => {
        this.handleGetArticleList();
      }
    );
  };

  // 获取文章列表
  handleGetArticleList = () => {
    Axios.post("/jie/api/new/getArticleList", {
      ...this.state.formData,
    })
      .then(res => {
        const {data} = res.data;
        this.setState(
          {
            list: data.list,
            total: data.count,
            pagination: {
              ...this.state.pagination,
              total: data.count,
            }
          },
          () => {
            console.log(this.state.list);
            this.setState({
              loading: false,
            });
          }
        );
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export default ArticleList;
