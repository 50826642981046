import Home from './pages/user_conetent/index.jsx'
import Article from './pages/article_list/router'
import ImgPage from "./pages/img_manage/router";
import Column from "./pages/column/index.jsx"

import {UserOutlined, BookOutlined, FileImageOutlined, SnippetsOutlined} from '@ant-design/icons';

const routers = [
  {
    path: '/home',
    name: '主页',
    component: Home,
    icon: <UserOutlined/>
  },
  {
    path: '/article',
    name: '文章列表',
    component: Article,
    icon: <BookOutlined/>
  },
  {
    path: '/imgManage',
    name: "哈希图片",
    component: ImgPage,
    icon: <FileImageOutlined/>
  },
  {
    path: '/column',
    name: '栏目管理',
    component:Column,
    icon: <SnippetsOutlined />
  }
]

export default routers