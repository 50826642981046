import React, { Component } from "react";
import { UserMain, LogoBox, UploadLogo, FormRow } from "./style.jsx";
import { Upload, Input, Button, message, Spin } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import axios from "axios";

class UserContent extends Component {
	constructor() {
		super();
		this.state = {
			imageUrl: "",
			formData: {
				username: "",
				password: "",
				nickname: "",
				logo: "",
			},
			loading: true,
		};
	}

	render() {
		return (
			<UserMain>
				{this.state.loading && (
					<div className="loading_box">
						<Spin />
					</div>
				)}
				<div className="user_logo">
					<Upload style={{ width: "200px" }} name="img" listType="picture-card" className="avatar-uploader" showUploadList={false} action="api/new/uploadImage" onChange={this.handleImageUpdateing}>
						{this.state.formData.logo ? (
							<LogoBox>
								<img src={this.state.formData.logo} alt="avatar" style={{ width: "100%" }} />
								<div className="upload_show">
									<span>点击上传头像</span>
								</div>
							</LogoBox>
						) : (
							<UploadLogo>
								<PlusOutlined />
								<span>上传图片</span>
							</UploadLogo>
						)}
					</Upload>
					<FormRow>
						<Input placeholder="请输入用户名" value={this.state.formData.name}></Input>
					</FormRow>
					<FormRow>
						<Input placeholder="请输入昵称" value={this.state.formData.nickname} onChange={this.handleSetNickname}></Input>
					</FormRow>
					<FormRow>
						<Input.Password placeholder="请输入密码" value={this.state.formData.password} onChange={this.handleSetPassword}></Input.Password>
					</FormRow>
					<FormRow>
						<Button type="primary" onClick={this.handleUpdateUserInfo}>
							保存
						</Button>
					</FormRow>
				</div>
			</UserMain>
		);
	}

	componentDidMount = () => {
		this.handleGetUserInfo();
	};

	// 获取用户信息
	handleGetUserInfo = () => {
		axios
			.post("/jie/api/new/getUserInfo")
			.then(res => {
				const { data } = res.data;
				this.setState({ formData: data, loading: false });
			})
			.catch(err => {
				console.log(err);
			});
	};

	// 修改用户昵称
	handleSetNickname = e => {
		const { value } = e.target;
		const { formData } = this.state;
		formData.nickname = value;
		this.setState({ formData });
	};

	// 修改用户信息表单提交
	handleUpdateUserInfo = () => {
		const { logo, nickname, password } = this.state.formData;
		// if (logo === '') {
		//   message.error('为了凸显你的高颜值，请上传一个头像')
		//   return
		// }
		// if (nickname === '') {
		//   message.error('每个人都有自己的名字，你也不例外哦~')
		//   return
		// }
		// if (password === '') {
		//   message.error('密码是你账户的安全保障，请一定不要忘了TA！')
		//   return
		// }

		axios
			.post("api/new/updateUserInfo", {
				...this.state.formData,
			})
			.then(res => {
				if (res.data.ecode === 0) {
					message.success("修改成功,你又变帅了");
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	// 文件上传时
	handleImageUpdateing = response => {
		if (response.file.status === "done") {
			const { formData } = this.state;
			formData.logo = response.file.response.data.url;
			this.setState({
				formData,
			});
			message.success("上传成功了，看看满不满意");
		}
	};
}

export default UserContent;
