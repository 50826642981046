import styled from 'styled-components'

export const Main = styled.div`
    margin-left:-24px;
    position:relative;
    .my-component{
        border:1px solid #ccc;
    }

    .ant-upload.ant-upload-select-picture-card{
        background-color:transparent;
        width:150px;
        height:150px;
    }

    .example{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:9999999;
        text-align: center;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        display:flex;
        align-items:center;
        justify-content:center;
    }

`