import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Button, Result } from "antd";
import {ContextBox} from "./style"

const getArticleDetail = async id => {
	return Axios.post("/jie/api/new/getArticleInfo", { id });
};

export default function Detail(props) {
	const { id } = props.match.params;
	const [data, updateData] = useState({ ecode: 0, msg: "", data: {} });

	useEffect(() => {
		getArticleDetail(id)
			.then(res => {
				console.log("更新参数：", res.data);
				updateData(res.data);
			})
			.catch(err => {
				console.log("系统错误：", err);
			});
	}, []);

	return data.ecode === -1 ? (
		<Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." extra={<Button type="primary">Back Home</Button>} />
	) : (
		<ContextBox dangerouslySetInnerHTML={{ __html: data.data.context }}></ContextBox>
	);
}
