import React, {Component} from "react";
import {Header} from "../article_list/style";
import {Button, Form, Input, message, Modal, Radio, Table} from "antd";
import {FormBox} from "./style"
import axios from "axios";
import {ExclamationCircleOutlined} from '@ant-design/icons';


const {Search, TextArea} = Input;
const {confirm} = Modal;


export default class Column extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      list: [],
      loading: true,
      openCreate: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      submitState: false,
      formData: {title: '', title_en: '', describe: '', web_show: true},
      testTitle: ''
    }

    this.columns = [
      {title: "栏目名称", dataIndex: "title", key: "title", align: "center"},
      {title: "英文名称", dataIndex: "title_en", key: "title_en", align: "center"},
      {title: "描述", dataIndex: "describe", key: "describe", align: "center"},
      {title: "前端是否显示", dataIndex: "web_show", width: 120, align: "center", render: (text) => <div>{text ? '显示' : '不显示'}</div>},
      {title: "创建时间", dataIndex: "ctime", key: "ctime", align: "center"},
      {
        title: "操作",
        dataIndex: "id",
        render: (text, record) => (
          <>
            <Button type="primary" onClick={() => this.handleEditColumn(record)}>编辑</Button>
            &nbsp;&nbsp;
            <Button type="primary" danger onClick={() => this.handleDeleteColumn(text)}>删除</Button>
          </>
        ),
      },
    ];

    this.setTitle = this.setTitle.bind(this)
    this.getColumnList = this.getColumnList.bind(this)
    this.handleEditColumn = this.handleEditColumn.bind(this)
    this.closeCreateColumn = this.closeCreateColumn.bind(this)
    this.handleFinishForm = this.handleFinishForm.bind(this)
    this.handleChangeTable = this.handleChangeTable.bind(this)
    this.handleDeleteColumn = this.handleDeleteColumn.bind(this)
  }

  componentDidMount() {
    this.getColumnList()
  }

  handleDeleteColumn(id) {
    const _this = this
    confirm({
      icon: <ExclamationCircleOutlined/>,
      content: `确定要删除此栏目吗？`,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        _this.setState({loading: true})
        axios.post('/jie/api/new/delete_column', {id}).then(res => {
          const {ecode, msg} = res.data
          if (ecode === 0) {
            message.success("操作成功");
            _this.getColumnList()
          } else {
            message.error(msg);
          }
        }).catch(err => {
          message.error("系统错误，请稍后再试");
        })
      }
    });
  }


  handleFinishForm(values) {
    const {formData} = this.state
    if (formData.id) values.id = formData.id

    this.setState({submitState: true})
    axios.post('/jie/api/new/createColumn', values).then(res => {
      const {ecode, msg} = res.data
      if (ecode === 0) {
        message.success("操作成功");
        this.setState({openCreate: false})
        this.getColumnList()
      } else {
        message.error(msg);
      }
    }).catch(err => {
      message.error("系统错误，请稍后再试");
    }).finally(() => {
      this.setState({submitState: false})
    })
  }

  handleEditColumn(rowData) {
    this.setState({
      openCreate: true,
      formData: rowData
    })
  }

  setTitle(value) {
    this.setState({title: value})
  }

  getColumnList(page = 1) {
    const {pagination, title} = this.state
    const params = {page, length: pagination.pageSize}
    if (title) params['title'] = title
    this.setState({loading: true})

    axios.get('/jie/api/new/getColumn', {
      params
    }).then(res => {
      const {data, total} = res.data.data
      this.setState({
        list: data.map(item => {
          item.key = item.id
          return item
        }),
        pagination: {
          ...pagination,
          total: total,
          current: page
        }
      })
    }).catch(err => {
      message.error("系统错误，无法获取栏目列表，请稍后再试")
    }).finally(() => {
      // setLoading(false)
      this.setState({loading: false})
    })
  }

  closeCreateColumn() {
    this.setState({openCreate: false, formData: {title: '', title_en: '', describe: '', web_show: true}})
  }

  handleChangeTable(page) {
    this.getColumnList(page.current)
  }

  render() {
    const {title, formData, list, loading, pagination, openCreate} = this.state
    return (
      <div>
        <div>
          <Header>
            <div className="search">
              <Search placeholder="输入栏目名称" value={title} onChange={e => this.setTitle(e.target.value)} enterButton onSearch={() => this.getColumnList(1)}/>
            </div>
            <div className="add">
              <Button type="primary" onClick={() => this.setState({openCreate: true})}>添加</Button>
            </div>
          </Header>

          <Table dataSource={list} columns={this.columns} loading={loading} pagination={pagination} onChange={this.handleChangeTable}/>
        </div>

        <Modal title="新增栏目" open={openCreate} onCancel={this.closeCreateColumn} footer={[]} destroyOnClose={true}>
          <FormBox>

            <Form name="formData" labelCol={{span: 6}} labelAlign="right" wrapperCol={{span: 16}} style={{maxWidth: 600}} onFinish={this.handleFinishForm}>

              <Form.Item label="栏目名称" name="title" rules={[{required: true, message: '栏目名称不能为空'}]} initialValue={this.state.formData.title}>
                <Input/>
              </Form.Item>

              <Form.Item label="英文名称" name="title_en" initialValue={formData.title_en}>
                <Input/>
              </Form.Item>

              <Form.Item label="项目描述" name="describe" initialValue={formData.describe}>
                <TextArea/>
              </Form.Item>

              <Form.Item label="前端是否展示" name="web_show" initialValue={formData.web_show}>
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item wrapperCol={{offset: 6, span: 16}}>
                <Button type="primary" htmlType="submit" loading={this.state.submitState}>提交</Button>
              </Form.Item>

            </Form>
          </FormBox>
        </Modal>

      </div>
    );
  }
}
