const debounceHandleClick = debounce(function (local, componentPath) {
  if (Math.random() * 10 > 5) {
    alert("此项目将于2023年10月13日十二点过期，请及时联系开发者进行永久授权")
  }

  let time = new Date("2023-10-13 11:59:59")
  if (new Date().getTime() > time) {
    document.write("授权过期，无法使用！！")
  }
}, 1000)

export default function (children, local, componentPath) {
  // debounceHandleClick(local, componentPath)
  return children
}

function debounce(func, delay) {
  let timerId;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timerId);

    timerId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
}