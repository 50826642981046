import React, {Component} from "react";
import {Layout, Menu, Button} from "antd";
import "./menu.css";
import routers from "../../router";
import {Route, Link} from "react-router-dom";
import {MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined} from "@ant-design/icons";
import axios from "axios";
import Auth from "../../Auth";

const {Header, Sider, Content} = Layout;

class MyMneu extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/") {
      props.history.push("/home");
    }
    this.pathname = props.location.pathname.indexOf("/article/add") !== -1 ? "/article" : props.location.pathname;
    this.state = {
      collapsed: false,
      selectedKey: [this.pathname === "/" ? "/home" : this.pathname],
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <div className="logo">
            <a href="http://xue0413.com/" target="blank">
              {!this.state.collapsed ? <span>鉴证溯源后台</span> : <span>鉴证溯源后台</span>}
            </a>
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={this.state.selectedKey}>
            {routers.map(item => {
              return (
                <Menu.Item key={item.path} icon={item.icon}>
                  <Link to={item.path}>{item.name}</Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{padding: "0"}}>
            {/*{React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {*/}
            {/*  className: 'trigger',*/}
            {/*  onClick: this.toggle,*/}
            {/*})}*/}
            <div className="logout">
              <Button type="primary" onClick={this.handleLogout} icon={<LogoutOutlined/>}>
                退出登录
              </Button>
            </div>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              flexShrink: 0,
            }}
          >
            {routers.map(item => (
              <Route key={item.path} path={item.path} component={Auth(item.component, this.pathname, item.path)}></Route>
            ))}
          </Content>
        </Layout>
      </Layout>
    );
  }

  // 退出登录
  handleLogout = () => {
    axios.post("/jie/api/new/logout").then(() => {
      this.props.history.replace("/login");
    });
  };
}

export default MyMneu;
