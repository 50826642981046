import styled from 'styled-components'

export const UserMain = styled.div`
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    position: relative;

    .ant-upload.ant-upload-select-picture-card{
        width:auto;
        height:auto;
        margin-right:0;
        margin-bottom:18px;
    }

    .ant-upload-picture-card-wrapper{
        width:auto;
    }

    .user_logo{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }

    .loading_box{
        width:100%;
        height:100%;
        position:absolute;
        display:flex;
        align-items:center;
        justify-content:center;
        z-index:100;
        background-color:rgba(255,255,255,.8)
    }

`

export const LogoBox = styled.div`
    width:200px;
    height:200px;
    border-radius:50%;
    overflow:hidden;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;

    :hover .upload_show{
        opacity:1;
    }

    .upload_show{
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        position:absolute;
        top:0;
        left:0;
        background-color:rgba(0,0,0,.3);
        opacity:0;
        transition:all .2s;
        span{
            color:#fff;
            font-size:18px;
        }

    }
`

export const UploadLogo = styled.div`
    width:200px;
    height:200px;
    display:flex;
    align-items:center;
    justify-content:center;

    span{
        color:#000;
        font-size:18px;
    }

`

export const FormRow = styled.div`
    width:300px;
    height:50px;

    button{
        width:100%;
    }

`