import styled from 'styled-components'

import bg from '../../images/login_bg.svg'

export const LoginMain = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${bg});
  background-position: center center;
  background-size: cover;

  .login_header {
    margin-bottom: 30px;
  }

  .logo {
    height: 56px;
    /* background: rgba(255, 255, 255, 0.3); */
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .logo span {
    font-weight: bold;
    color: transparent;
    font-size: 56px;
    white-space: nowrap;
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    background-clip: text;
  }

`

export const FormmRow = styled.div`
    width:300px;
    height:50px;

    button{
        width:100%;
    }
`

