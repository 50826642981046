import {Header} from "../article_list/style";
import {Table, Button, Input, Image, Switch, message, Modal} from "antd";
import React, {useState, useEffect} from "react";
import axios from "axios";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {ImgBoxMange} from './style.jsx'

const {Search} = Input;

export default function ImgManage(props) {
  const [imgTitle, updateTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSearchArticle = () => {
    getList();
  };

  const getList = (page = 1) => {
    setLoading(true);
    return axios
      .get("/jie/api/new/getHashImg", {
        params: {hash: imgTitle, page, length: 10},
      })
      .then(res => {
        const {list, total} = res.data.data;
        setList([].concat(list));
        setPagination({
          ...pagination,
          total,
        });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        message.error("获取图片列表失败，请稍后再试或联系管理员");
      });
  };

  useEffect(getList, []);

  const addArticleSelect = () => {
    props.history.push("/imgManage/upload");
  };

  const handleSwitchState = (e, rowData) => {
    // console.log(e, rowData)
    setConfirmLoading(true);
    axios
      .post("/jie/api/new/patchHashState", {
        hash: rowData.hash,
        state: e ? "show" : "hide",
      })
      .then(res => {
        console.log("修改结果：", res.data);
        const {hash, state} = res.data.data;
        setList(
          list.map(item => {
            if (item.hash === hash) item.state = state;
            return item;
          })
        );
        setConfirmLoading(false);
        message.success("操作成功");
      })
      .catch(err => {
        setConfirmLoading(false);
        message.success("操作失败，请稍后再试或联系管理员");
      });
  };

  const handleDeleteImg = hash => {
    console.log("hash", hash);
    const {confirm} = Modal;
    confirm({
      title: "删除照片",
      icon: <ExclamationCircleFilled/>,
      content: "删除后将不可恢复，确定要删除此照片吗？",
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        setLoading(true);
        axios
          .delete("/jie/api/new/deleteHashImg", {
            data: {hash},
          })
          .then(_ => {
            message.success("操作成功");
            getList();
          })
          .catch(err => {
            console.log("操作失败，请稍后再试或联系管理员！");
          });
      },
    });
  };

  const handleChangeTable = page => {
    // console.log(page)
    getList(page.current).then(_ => {
      setPagination({
        ...pagination,
        current: page.current,
      });
    });
  };

  const columns = [
    {title: "标题", dataIndex: "title", key: "title", align: "center"},
    {title: "hash值", dataIndex: "hash", key: "hash", align: "center"},
    {title: "创建时间", dataIndex: "ctime", key: "ctime", align: "center"},
    {
      title: "状态",
      dataIndex: "state",
      align: "center",
      render: (state, rowData) => <Switch onChange={e => handleSwitchState(e, rowData)} checked={state === "show"} checkedChildren="公开" unCheckedChildren="隐藏" loading={confirmLoading}/>,
    },
    {title: "图片", dataIndex: "url", align: "center", render: (url, rowData) => <ImgBoxMange>{url.split(',').map(item => <Image src={item} title={rowData.title} height={50} />)}</ImgBoxMange>},
    {
      title: "操作",
      dataIndex: "hash",
      render: hash => (
        <Button type="primary" danger onClick={() => handleDeleteImg(hash)}>
          删除
        </Button>
      ),
    },
  ];
  return (
    <div>
      <div>
        <Header>
          <div className="search">
            <Search placeholder="输入图片hash值" value={imgTitle} onSearch={handleSearchArticle} onChange={e => updateTitle(e.target.value)} enterButton/>
          </div>
          <div className="add">
            <Button type="primary" onClick={() => props.history.push("/hashSearch")}>
              外部访问
            </Button>
            &nbsp;
            <Button type="primary" onClick={addArticleSelect}>
              添加
            </Button>
          </div>
        </Header>

        <Table dataSource={list} columns={columns} loading={loading} pagination={pagination} onChange={handleChangeTable}/>
      </div>
    </div>
  );
}
