import React from "react";
import {Route} from "react-router-dom";
import ImgManage from "./index";
import ImgUpload from "./img_upload";

const ImgPage = () => {
  return (
    <div>
      <Route path="/imgManage" exact component={ImgManage}></Route>
      <Route path="/imgManage/upload" exact component={ImgUpload}></Route>
    </div>
  )
}

export default ImgPage
