import React, {Fragment, useState} from "react";
import {InboxOutlined, PlusOutlined} from "@ant-design/icons";
import ImgCrop from 'antd-img-crop';
import {message, Upload, Form, Input, Select, Button, Modal} from "antd";
import axios from "axios";

const {Dragger} = Upload;

export default function ImgUpload(props) {
  // const [formData, updateForm] = useState({title: '', state: 'show', url: 'http://localhost:8000/static/update/2023-07-18/1689665245.jpg'})
  const [formData, updateForm] = useState({title: "", state: "show", url: ""});
  const handleChangeTitle = e => {
    formData.title = e.target.value;
    updateForm({...formData});
  };
  const handleChangeState = value => {
    formData.state = value;
    updateForm({...formData});
  };

  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  // const handleImageUpdateing = response => {
  //   if (response.file.status === "done") {
  //     formData.url = formData.url.split(',').filter(Boolean)
  //     formData.url.push(response.file.response.data.url)
  //     formData.url = formData.url.join(',')
  //     updateForm({...formData});
  //     message.success("上传成功!");
  //   }
  // };

  const handleSubmitForm = () => {
    console.log(formData)
    axios
      .post("/jie/api/new/createHashImg", {
        ...formData,
      })
      .then(res => {
        if (res.data.ecode === 0) {
          message.success("操作成功");
          props.history.push("/imgManage");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch(err => {
        message.success("操作失败，请稍后再试或联系管理员");
      });
  };

  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div style={{marginTop: 8}}>添加照片</div>
    </div>
  );

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({fileList: newFileList}) => {
    setFileList(newFileList)
    formData.url = newFileList.map(item => {
      if (item.response) return item.response.data.url
    }).filter(Boolean).join(",")

    updateForm({...formData});
  };

  const handleCancel = () => setPreviewOpen(false);


  return (
    <Fragment>
      <Form style={{maxWidth: 600}} labelCol={{span: 3}} name="basic" onFinish={handleSubmitForm}>
        <Form.Item label="标题">
          <Input value={formData.title} onChange={handleChangeTitle}/>
        </Form.Item>
        <Form.Item label="公开">
          <Select
            onChange={handleChangeState}
            defaultValue="show"
            options={[
              {value: "show", label: "公开"},
              {value: "hide", label: "隐藏"},
            ]}
          />
        </Form.Item>
        <Form.Item label="图片" name="url">
          {/*<Dragger name="img" accept="image/*" maxCount={6} multiple showUploadList={false} action="/jie/api/new/uploadImage" onChange={handleImageUpdateing}>*/}
          {/*  {formData.url === "" ? (*/}
          {/*    <>*/}
          {/*      <p className="ant-upload-drag-icon">*/}
          {/*        <InboxOutlined/>*/}
          {/*      </p>*/}
          {/*      <p className="ant-upload-text">单击或拖动文件到此区域进行上传</p>*/}
          {/*    </>*/}
          {/*  ) : (*/}
          {/*    formData.url.split(',').map(url => <ImgBox>*/}
          {/*      <img src={url} alt={formData.title}/>*/}
          {/*    </ImgBox>)*/}
          {/*  )}*/}
          {/*</Dragger>*/}
          <ImgCrop rotationSlider aspectSlider showGrid modalOk="确定" modalCancel="取消">
            <Upload
              action="/jie/api/new/uploadImage"
              listType="picture-card"
              name="img"
              accept="image/*"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 6 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
              <img alt="example" style={{width: '100%'}} src={previewImage}/>
            </Modal>
          </ImgCrop>
        </Form.Item>
        <Form.Item wrapperCol={{offset: 3}}>
          <Button type="primary" htmlType="submit">
            发布
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}
