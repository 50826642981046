import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Input, Image, message, Spin} from "antd";
import axios from "axios";

import {ImgBox, Main} from "./style";

const {Search} = Input

function formatStandardTime(timestamp) {
  // 创建一个新的 Date 对象，使用时间戳作为参数
  const date = new Date(timestamp);

  // 获取日期和时间的各个部分
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;

  // 构建格式化的日期字符串
  return year + "年" + month + "月" + day + "号";
}

export default function HashSearch() {
  const {hash} = useParams()
  const [hashVal, setHashVal] = useState(hash)

  const [loading, setloading] = useState(true)
  const [imgList, setImgList] = useState([])
  const [imgAttribute, setImgAttribute] = useState({hash: '', ctime: ''})

  useEffect(() => {
    getHashImg()
  }, [])

  const getHashImg = () => {
    if (!hashVal) {
      setloading(false)
      return
    }
    setloading(true)
    axios.get('/jie/api/new/getHashImgOne', {
      params: {hashVal}
    }).then(res => {
      console.log(res.data)
      const {ecode, msg, data} = res.data
      if (ecode === -1) {
        message.warning(msg)
      } else {
        setImgList(data.url.split(','))
        setImgAttribute({hash: data.hash, ctime: data.ctime})
      }
      setloading(false)
    }).catch(err => {
      message.error("获取hash失败，请稍后再试或联系管理员")
      console.log(err)
      setloading(false)
    })
  }

  const changeInput = (e) => {
    setHashVal(e.target.value)
  }

  return (
    <Main>
      <div className="searchBox">
        <Search placeholder="请输入hash值" enterButton onSearch={getHashImg} onChange={changeInput} value={hashVal}/>
      </div>
      <ImgBox>
        {loading ? (<div className="example"><Spin/></div>) : <></>}

        {
          imgList.length >= 1 ? (
            <>
              <div className="show_context">
                <div className="title">存证查询结果</div>
                <div className="row_box">
                  <div className="row">
                    <span className="label">创建时间：</span>
                    <span className="row_content">{formatStandardTime(imgAttribute.ctime * 1000)}</span>
                  </div>
                  <div className="row">
                    <span className="label">图片哈希：</span>
                    <span className="row_content">{imgAttribute.hash}</span>
                  </div>

                  <div className="row">
                    <span className="label">图片真伪：</span>
                    <span className="row_content">真实</span>
                  </div>

                </div>
              </div>
              <div className="img_box">
                <Image.PreviewGroup>
                  {
                    imgList.map(item => <Image src={item}/>)
                  }
                </Image.PreviewGroup>
              </div>
            </>
          ) : <div className="not_found">没有可用照片，请输入hash值开始查询</div>
        }

      </ImgBox>
    </Main>
  )
}